import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, of, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  DateTypes,
  ListViewAPIPayload,
  ListViewMinMaxDates,
  SLADates,
  SLATypes,
  SummaryViewAPIPayload,
} from '../../components/sla/model/interface';
import { AutoFileDownloadService } from '../auto-file-download-service/auto-file-download.service';
@Injectable({
  providedIn: 'root',
})
export class SlaOnTimeShipService {
  radialUrl = environment.baseUrl;
  rootUrl = environment.rootUrl;
  slaFilterValues: any;
  previousClient: any;
  setFilterDateType$ = new BehaviorSubject<DateTypes>(null);
  setSLAStatusFilterText$ = new BehaviorSubject<SLATypes>(null);
  listViewDateRangeMinMax$ = new Subject<ListViewMinMaxDates>();
  setFilterDates$ = new BehaviorSubject<SLADates>({
    startDate: null,
    endDate: null,
  });
  slaSummaryViewPayLoad: SummaryViewAPIPayload;
  dailySummaryViewData: any;
  monthlySummaryViewData: any;
  weeklySummaryViewData: any;
  quarterlySummaryViewData: any;
  slaListViewPayLoad: ListViewAPIPayload;
  constructor(
    private readonly http: HttpClient,
    private autoFileDownloadService: AutoFileDownloadService
  ) {}
  getSlaFilterValues(client) {
    if (this.slaFilterValues && client === this.previousClient) {
      return of(this.slaFilterValues);
    } else {
      return this.http
        .post<any>(this.radialUrl + '/sla/ontimeship/getfilters', null)
        .pipe(
          tap((data) => {
            if (data.statusmessage === 'Success') {
              this.slaFilterValues = data;
              this.previousClient = client;
            }
          })
        );
    }
  }
  getListView(payLoad: ListViewAPIPayload) {
    this.slaListViewPayLoad = payLoad;
    return this.http.post<any>(
      this.radialUrl + '/sla/ontimeship/getlistview',
      payLoad
    );
  }
  getSlaSummaryView(payLoad: SummaryViewAPIPayload) {
    this.slaSummaryViewPayLoad = payLoad;
    return this.http
      .post<any>(this.radialUrl + '/sla/ontimeship/getsummaryview', payLoad)
      .pipe(
        tap({
          next: (data: any) => {
            switch (payLoad.summaryType.toUpperCase()) {
              case 'D': {
                this.dailySummaryViewData = data;
                break;
              }
              case 'M': {
                this.monthlySummaryViewData = data;
                break;
              }
              case 'Q': {
                this.quarterlySummaryViewData = data;
                break;
              }
              case 'W': {
                this.weeklySummaryViewData = data;
                break;
              }
            }
            data['summaryType'] = payLoad?.summaryType;
          },
        }),
        catchError((error) => {
          error['summaryType'] = payLoad?.summaryType;
          return of(error);
        })
      );
  }
  downloadSlaData(summaryTypeModify: any, summeryPage: string) {
    const endUrl =
      summeryPage === 'List' ? '/slalisting/download' : '/slasummary/download';
    return (
      this.http.post<any>(this.rootUrl + endUrl, summaryTypeModify).subscribe({
        next: (data) => {
          this.showSuccessMsg(data);
        },
        error: () => {},
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }
  showSuccessMsg(data: any) {
    const clientName = sessionStorage.getItem('companyname') || '';
    const uuid = sessionStorage.getItem?.('uuid');
    data.downloadOf = 'sla';
    Object.assign(data, { clientName, uuid });
    this.autoFileDownloadService.autoFileDownload(data);
  }
}
