export const slaSummeryViewHeader = [
  { field: 'date', header: 'Date' },
  { field: 'shippingDate', header: 'Shipping date' },
  { field: 'orderShipped', header: 'Order shipped' },
  { field: 'ordersPassed', header: 'Orders passed' },
  { field: 'ordersFailed', header: 'Orders failed' },
  { field: 'unshippedOrders', header: 'Unshipped orders' },
  { field: 'exceptionOrders', header: 'Exception orders' },
  { field: 'SLA1', header: 'SLA1 %' },
];
export const slaListViewHeader = [
  { field: 'weborder_nbr', header: 'Order Nr' },
  { field: 'shipped_dttm', header: 'Shipped Date' },
  { field: 'interfaced_dttm', header: 'Interfaced Date' },
  { field: 'due_date', header: 'due_date' },
  { field: 'order_status', header: 'Order Status' },
  { field: 'sla_status', header: 'SLA1 Status' },
  { field: 'exception_comment', header: 'Exception Comment' },
  { field: 'pickup', header: 'Pickup Date(PNH)' },
];
